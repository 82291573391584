<style lang="scss">
  .select-colored.v-select {
    &.v-select--is-menu-active > .v-input__slot,
    &.v-input--is-focused > .v-input__slot {
      border-radius: inherit !important;
    }

    .v-input__slot {
      min-height: 40px;
      height: 40px;
    }

    .v-select__slot {
      justify-content: center;
    }

    &.v-text-field--outlined fieldset {
      border-width: 0px;
    }

    .v-select__selections {
      flex: 0 0 auto;
      width: initial;

      input {
        margin: 0px;
        max-width: 0px;
        min-width: 0px;
        width: 0px;
      }
    }

    .v-select__selection--comma {
      max-width: inherit;
      margin-right: 0px;
    }

    .v-input__append-inner {
      flex-shrink: initial;
      margin-top: 8px;
      margin-left: 0px;
      padding: 0;
    }
  }

  .select-colored-content .v-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
<template>
  <div>
    <v-select
      :background-color="color"
      class="select-colored font-weight-bold text-caption"
      dark
      hide-details
      :menu-props="{ maxHeight: 400, contentClass: 'select-colored-content', offsetY: true }"
      :items="items"
      rounded
      outlined
      :value="value"
      @input="onInput"
      :disabled="loading"
    >
      <template #prepend-item>
        <v-subheader class="text-uppercase">Status</v-subheader>
      </template>
      <template #item="{ item: { color, text }, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" class="align-stretch pl-0">
          <v-sheet class="mr-4" :color="color" width="8px"></v-sheet>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <confirm-dialog
      v-if="modalPublishProgramOpen"
      v-model="modalPublishProgramOpen"
      @confirm="updateProgramStatus(selectedStatus)"
      :title="publishStatusConfirmation.title"
      :message="publishStatusConfirmation.message"
    />
    <confirm-dialog
      v-if="modalUnpublishProgramOpen"
      v-model="modalUnpublishProgramOpen"
      @confirm="updateProgramStatus(selectedStatus)"
      :title="unpublishStatusConfirmation.title"
      :message="unpublishStatusConfirmation.message"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { ConfirmDialog } from '@/components';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';
  import ProgramService from '@/services/program/program';
  import {
    PROGRAMS_STATUS,
    PROGRAMS_STATUS_COLORS,
    PROGRAMS_STATUS_OPTIONS,
    PUBLISH_STATUS_CONFIRMATION,
    UNPUBLISH_STATUS_CONFIRMATION,
  } from '@/constants/programs';
  import { publishProgramToClientPublications } from '@/utils/publications';
  // import { sendSqsMessage } from '@/utils/aws-sqs';

  export default Vue.extend({
    components: {
      ConfirmDialog,
    },
    props: {
      program: {
        type: [String, Number],
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        selectedStatus: 0,
        modalPublishProgramOpen: false,
        modalUnpublishProgramOpen: false,
      };
    },
    computed: {
      items() {
        return PROGRAMS_STATUS_OPTIONS;
      },
      color(): string | undefined {
        return PROGRAMS_STATUS_COLORS[this.value];
      },
      publishStatusConfirmation() {
        return PUBLISH_STATUS_CONFIRMATION;
      },
      unpublishStatusConfirmation() {
        return UNPUBLISH_STATUS_CONFIRMATION;
      },
    },
    methods: {
      ...mapActions('programs', { changeStatus: 'changeStatus', fetchProgram: 'fetchProgram' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchClient: ClientService.fetchClient,
      fetchSport: ClientService.fetchSport,
      async onInput(status: number) {
        // this.selectedStatus = status;
        // const oldStatus = this.value;
        // if (status == PROGRAMS_STATUS.PUBLISHED) {
        //   this.modalPublishProgramOpen = true;
        // } else if (oldStatus == PROGRAMS_STATUS.PUBLISHED) {
        //   this.modalUnpublishProgramOpen = true;
        // } else {
        await this.updateProgramStatus(status);
        // }
      },
      async updateProgramStatus(status: number) {
        const oldStatus = this.value;
        try {
          this.loading = true;
          await this.changeStatus({
            id: this.program,
            oldStatus,
            status,
          });
          if (status == PROGRAMS_STATUS.PUBLISHED) {
            await this.publishProgramToSchool();
          }
        } catch (error) {
          const msg = error.response?.data || 'Could not change program status';
          this.showToast(msg);
        } finally {
          this.loading = false;
        }
      },
      async publishProgramToSchool() {
        const program = await ProgramService.fetchProgram(this.program);

        const { clientId, id: programId } = program;
        // const { tenantId } = await this.fetchClient(clientId);
        // const { clientSportId } = program.sport;
        // const sport = clientSportId ? await this.fetchSport({ clientId, clientSportId }) : null;
        // const sportName = sport ? sport.shortName : 'programs';
        // const link = `${config.frontendUrl}/${tenantId}/${sportName}/${programId}/`;
        // const { scheduleId: eventId } = program.event;

        // auto-publish digital program to school's publications page
        try {
          await publishProgramToClientPublications(programId);
        } catch (error) {
          const msg = error.response?.data || 'Could not auto-publish the program to the school';
          this.showToast(msg);
        }

        // auto-publish digital program link to the event's game program field
        // if (eventId > 0) {
        //   try {
        //     await sendSqsMessage(tenantId, eventId, link);
        //   } catch (error) {
        //     const msg = error?.response?.data || "Could not initiate adding the program to the event's game links";
        //     this.showToast(msg);
        //   }
        // }
      },
    },
  });
</script>
