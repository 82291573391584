<template>
  <div class="dashboard-view">
    <app-bar>
      Dashboard
      <template #actions>
        <nav-action-primary @click="toggleModal()">Create new program</nav-action-primary>
      </template>
    </app-bar>
    <v-container class="pt-0" fluid>
      <card class="overflow-hidden">
        <template #title>All Programs</template>
        <v-card-text class="px-6 pb-0 pt-4">
          <v-row>
            <v-col cols="12" lg="3" sm="6" md="3" v-if="userRole !== ROLES.CLIENT_ADMIN">
              <v-subheader id="client-label" class="px-0">Search by Client</v-subheader>
              <client-autocomplete
                aria-live="polite"
                v-model="filters.client"
                @change="refreshList"
              ></client-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" sm="6" md="3">
              <v-subheader id="sport-label" class="px-0">Filter by Sport</v-subheader>
              <v-select
                aria-live="polite"
                hide-details
                outlined
                dense
                :items="sportsOptions"
                v-model="filters.sport"
                @change="refreshList"
                aria-labelledby="sport-label"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="3" sm="6" md="3">
              <v-subheader id="status-label" class="px-0">Filter by Status</v-subheader>
              <v-select
                aria-live="polite"
                hide-details
                outlined
                dense
                :items="statusFilterOptions"
                v-model="filters.status"
                @change="refreshList"
                aria-labelledby="status-label"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="3" sm="6" md="3">
              <v-subheader class="px-0">Search</v-subheader>
              <search
                dense
                placeholder="Sport, Keywords, Opponent, etc..."
                v-model="search"
                @submit="submitSearch"
              ></search>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-6" v-if="loading || !programs.length"></v-divider>
        <v-card-text class="pa-0">
          <v-sheet class="pt-4" v-if="!loading && programs.length" color="transparent">
            <v-data-table height="100%" hide-default-footer :options="options" :headers="headers" :items="programs">
              <template #item="{ item }">
                <tr class="table-row">
                  <td class="text-start name">
                    <v-row no-gutters>
                      <v-col class="flex-grow-0">
                        <v-img
                          class="mr-4"
                          maxWidth="44"
                          :alt="item.event.home.name"
                          :src="item.event.home.logoUrl"
                        ></v-img>
                      </v-col>
                      <v-col>
                        <p class="mb-1 font-weight-bold grey--text text--darken-3">{{ item.title }}</p>
                        <p class="mb-0 text-caption font-weight-bold grey--text text--darken-1">
                          {{ item.sport.name }}
                        </p>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-center date">
                    <template v-if="item.event.date">
                      <p class="mb-1 grey--text text--darken-3" v-format-date:[dateFormat]="item.event.date"></p>
                      <p class="mb-0 text-caption grey--text text--darken-1">
                        <v-icon color="grey darken-1" class="mr-1" size="14">mdi-clock-outline</v-icon>
                        <span v-format-date:[timeFormat]="item.event.date"></span>
                      </p>
                    </template>
                  </td>
                  <td class="text-center">
                    <v-sheet class="mx-auto" width="132" color="transparent">
                      <status-select :program="item.id" :value="item.status"></status-select>
                    </v-sheet>
                  </td>
                  <td class="text-center">
                    <actions-menu :program="item"></actions-menu>
                  </td>
                </tr>
              </template>
              <template #footer>
                <div class="v-data-footer">
                  <v-spacer></v-spacer>
                  <pagination
                    class="my-3"
                    align="end"
                    v-model="options.page"
                    total-visible="10"
                    :length="pages"
                  ></pagination>
                </div>
              </template>
            </v-data-table>
          </v-sheet>
          <v-sheet
            v-if="!loading && !programs.length"
            min-height="400"
            class="fill-height text-center text--disabled d-flex flex-column align-center justify-center"
            color="transparent"
          >
            <div v-if="hasFilters">
              <v-icon color="secondary lighten-4" size="52">mdi-text-search-variant</v-icon>
              <p class="mt-4 mb-2 text-h6 font-weight-medium">No results found.</p>
              <p class="mb-0 text-body-2">Please try changing the filters or search term.</p>
            </div>
            <div v-else>
              <v-icon color="secondary lighten-4" size="52">mdi-folder-plus-outline</v-icon>
              <p class="mt-4 mb-2 text-h6 font-weight-medium">You haven't created any programs yet</p>
              <p class="mb-0 text-body-2">Click the button in the upper right corner to create a program</p>
            </div>
          </v-sheet>
          <v-sheet
            v-if="loading"
            min-height="400"
            class="fill-height text-center d-flex flex-column align-center justify-center"
            color="transparent"
          >
            <v-progress-circular indeterminate size="48"></v-progress-circular>
          </v-sheet>
        </v-card-text>
      </card>
    </v-container>
    <NewProgramModal
      v-if="modalOpen"
      :program="program"
      :edit="edit"
      v-model="modalOpen"
      @save="refreshList"
    ></NewProgramModal>
    <page-preview-modal :current-page="1" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { AppBar, NavActionPrimary, Card, Pagination, Search } from '@/components';
  import { PagePreviewModal } from '@/components/Builder';
  import { mapActions, mapGetters } from 'vuex';
  import { PROGRAMS_STATUS_OPTIONS } from '@/constants/programs';
  import API from '@/services/api/api';
  import { sortBy } from 'lodash';
  import { ClientAutocomplete } from '@/components/Clients';
  import { StatusSelect, ActionsMenu, NewProgramModal } from '@/components/Programs';
  import { ROLES } from '@/constants/users';
  import { isNil } from 'lodash-es';

  export default Vue.extend({
    components: {
      AppBar,
      Card,
      NavActionPrimary,
      Pagination,
      Search,
      StatusSelect,
      ClientAutocomplete,
      ActionsMenu,
      NewProgramModal,
      PagePreviewModal,
    },
    name: 'Dashboard',
    data() {
      const hClass = 'grey--text text--darken-1 font-weight-regular text-body-2';
      return {
        dateFormat: 'EEE, LLL d',
        timeFormat: 'h:mma',
        loading: false,
        sportsOptions: [{ text: 'All', value: null }],
        statusFilterOptions: [{ text: 'All', value: null }, ...PROGRAMS_STATUS_OPTIONS],
        search: '',
        filters: {
          client: null,
          sport: null,
          status: null,
          keyword: '',
        },
        headers: [
          { text: 'School/Sport', sortable: false, class: hClass },
          { text: 'Event Date', align: 'center', sortable: false, class: hClass },
          { text: 'Status', align: 'center', sortable: false, class: hClass },
          { text: 'Actions', width: 120, align: 'center', sortable: false, class: hClass },
        ],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        statusOptions: PROGRAMS_STATUS_OPTIONS,
        ROLES,
        userRole: this.$store.getters['user/getUserRole'],
        modalOpen: false,
        edit: false,
        program: {},
      };
    },
    computed: {
      ...mapGetters('programs', { programs: 'getPrograms', pages: 'getPages' }),
      hasFilters(): boolean {
        return Object.values(this.filters).some(
          (value: null | number | string) =>
            (typeof value === 'number' && value > 0) || (typeof value === 'string' && value.trim().length)
        );
      },
    },
    methods: {
      ...mapActions('programs', { fetchPrograms: 'fetchPrograms' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      async fetchSports() {
        const { data } = await API.get('/globalservices/sports');
        const options = sortBy(data, (s) => s.name.toLowerCase()).map((sport: { id: string; name: string }) => ({
          text: sport.name,
          value: Number(sport.id),
        }));
        this.sportsOptions = [{ text: 'All', value: null }, ...options];
      },
      submitSearch() {
        const { search } = this;
        this.filters.keyword = search;
        this.options.page = 1;
        this.refreshList();
      },
      async refreshList() {
        this.loading = true;
        try {
          const { filters, $route } = this;
          if (!isNil($route.query?.clientId)) filters.client = parseInt($route.query?.clientId as string);
          const clientId = filters.client || undefined;
          const sportId = filters.sport || undefined;
          const status = filters.status || undefined;
          const keyword = filters.keyword.trim() || undefined;
          const PageSize = this.options.itemsPerPage;
          let { page: PageIndex } = this.options;
          await this.fetchPrograms({ PageIndex, PageSize, clientId, sportId, status, keyword });
        } catch (error) {
          this.showToast('Could not fetch programs list');
          // console.warn(error);
        }
        this.loading = false;
      },
      toggleModal() {
        this.modalOpen = !this.modalOpen;
        this.edit = false;
      },
      editProgram(value: any) {
        this.edit = true;
        this.program = value;
        this.modalOpen = !this.modalOpen;
      },
    },
    watch: {
      options: {
        handler() {
          this.refreshList();
        },
        deep: true,
      },
      search(newVal: string) {
        if (newVal === '') {
          this.filters.keyword = '';
          this.options.page = 1;
          this.refreshList();
        }
      },
    },
    created() {
      this.refreshList();
      this.fetchSports();
    },
  });
</script>
<style lang="scss" scoped>
  .table-row {
    height: 80px;

    td {
      &.name {
        min-width: 400px;
      }
      &.date {
        min-width: 120px;
      }
    }
  }
  .v-data-table--mobile {
    .table-row {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      height: 100px;
      td {
        border-bottom: none !important;
        padding-top: 10px;
      }
    }
  }
</style>
