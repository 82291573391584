<template>
  <v-dialog :max-width="650" :value="value" persistent>
    <v-card>
      <v-toolbar dark color="grey lighten-3" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text"> {{ title }} </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4 black--text">{{ message }}</v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <btn type="submit" @click="confirmAction" color="primary">Confirm</btn>
        <btn @click="closeModal">Cancel</btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Btn } from '.';
  export default Vue.extend({
    components: { Btn },
    props: {
      value: { type: Boolean, default: false },
      title: { type: String, default: 'Confirm Action' },
      message: { type: String, default: '' },
    },
    methods: {
      confirmAction() {
        this.closeModal();
        this.$emit('confirm');
      },
      closeModal() {
        this.$emit('input', false);
      },
    },
  });
</script>
